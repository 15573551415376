import React from "react";
import "bulma/css/bulma.css";
import "../styles/card.css";
import InstaComponent from "../components/instaComponent";

class Card extends React.Component {
  render() {
    return (
      <div className="thatMainCard">
        <InstaComponent url={this.props.url}></InstaComponent>
      </div>
    );
  }
}
export default Card;
