import React from "react";
import "../styles/instagram.css";
import Insta from "../../static/instagram.png";

class Instagram extends React.Component {
  render() {
    let link = "https://www.instagram.com/p/" + this.props.more + "/";

    return (
      <div className="mainInsta">
        <div className="instaHolder">
          <div className="firstPart">
            <img src={Insta} className="instaLogo" alt="" />
            <a
              href="https://www.instagram.com/joycerouhanajewelry/"
              rel="noopener noreferrer"
              className="instaLink"
            >
              View Profile
            </a>
          </div>
          <div className="secondPart">
            <img src={this.props.mainimage} className="secondImage" alt="" />
          </div>
          <div className="textSection">
            <p>{this.props.description}</p>
          </div>
          <div className="thirdPart">
            <a href={link} className="profileLink">
              View More on Instagram
            </a>
          </div>
        </div>
      </div>
    );
  }
}
export default Instagram;
