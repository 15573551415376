import React from "react";
import "bulma/css/bulma.css";
import Layout from "../components/layout";
import BackgroundImage from "../../static/heartSec.png";
import Card from "../components/card";
import Insta from "../templates/instaGrabber";
import Instacomp from "../components/instagramComponent";
class News extends React.Component {
  render() {
    return (
      <Layout backgroundimage={BackgroundImage}>
        <div className="newsSectionnb">
          <Insta></Insta>
        </div>
      </Layout>
    );
  }
}
export default News;
