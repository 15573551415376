import React from "react";
import { StaticQuery, graphql } from "gatsby";
import InstaComponent from "../components/instagramComponent";

export default () => (
  <StaticQuery
    query={graphql`
      query {
        allInstaNode {
          edges {
            node {
              id
              original
              caption
            }
          }
        }
      }
    `}
    render={data => (
      <div className="newsSection">
        {data.allInstaNode.edges.map((img, idx) => (
          <div className="newsSectionnb">
            <InstaComponent
              more={img.node.id}
              description={img.node.caption}
              mainimage={img.node.original}
            />
          </div>
        ))}
      </div>
    )}
  />
);
